import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/cloud_computing.jpg'
import person from '../../images/person.png'

const pageTitle = "Cloud Computing";
const pageSlug = "cloud-computing";
class CloudComputing extends React.Component{
  render(){
    return(
      <Layout pageType="technology-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">We offer cloud computing services for all the steps of your journey.</h6>
        </div>
      </section>

      <section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Cloud computing services are available all over the internet. The cloud provides excellent 
                    opportunities to accelerate business processes and innovation that were not possible a few years ago.
                    </h3>
                    <p>Despite how complex cloud services can be, their importance continues to grow, and there are many 
                        benefits of cloud computing for businesses. Some advantages include reduced costs, increased 
                        security, and greater reach while accelerating innovation.</p>
                    <span className="break"></span>
                    <h4>Cloud Computing Service Overview</h4>
                     <p>Our experts are experienced in a wide variety of different cloud solutions, and we're 
                         confident that we can find the right plan for you.</p>
                   <Link to="/contact-us" className="button green">Contact us</Link>
                    <span className="break"></span>
                    <h5>Cloud Computing Journey: </h5>
                    <p>Where are you on your cloud journey?</p>
                    <h5>Planning and Learning:</h5> 
                    <p>Are you learning and planning for a potential move to the cloud?</p>
                    <h5>Act:</h5> 
                    <p>Your organization is ready to go to the cloud.</p>
                    <h5>Optimize:</h5> 
                    <p>You want to optimize your cloud investment.</p>
                    <h5>Innovate:</h5>
                    <p>You are ready to take the next step in accelerating your 
                    innovative capabilities using cloud technologies.</p>
                    <span className="break"></span>
                    <h4>Phase 1 – Planning and Learning</h4>
                    <p>If you're planning and learning on how to move to the cloud, here are some plans that work:</p>
                    <h5>Cloud Strategy</h5>
                    <p>Migrating to the cloud doesn't have to be difficult; we find that our clients are most successful 
                        when they take a systematic approach. We assist our clients in outlining key drivers, business 
                        cases, and actionable strategies. To help their organization adopt cloud computing as an operating 
                        model and a technology.</p>
                    <h5>Cloud Assessment</h5>
                    <p>Our experts can review your current application infrastructure and how they relate to cloud readiness, 
                        modernization, future growth, and cloud strategy. Our tests are divided into several subsections; 
                        Security & Monitoring, Infrastructure, Alerts, Governance, Audits Applications, Development, and 
                        Operations. In each area, we provide context regarding your Current State, Analysis, Best Practices, 
                        and Recommendations. The assessment provides a roadmap that helps you understand your business and 
                        infrastructure requirements. It also provides essential resources for planning and shaping your 
                        cloud goals and decisions.</p>
                    <h5>Vendor Selection</h5>
                    <p>Our experts strive to be well informed and review factors such as available technology, pricing, and 
                        client preferences. To personalize your cloud vendor recommendation, we're always willing to help 
                        any way we can.</p>
                    <h5>Cloud Adoption</h5>
                    <p>With a framework for cloud Adoption in your company, you can facilitate a smoother migration to cloud-based
                         services. The Framework will help you move quickly through the process and dodge common organizational 
                         issues. These frameworks should include six core focus points. These are People, Business, Governance,
                          Platform, Security, and Operations. We can perform the analysis for each perspective to ensure that 
                          your organization will be properly aligned.</p>
                    <span className="break"></span>
                    <p>Not sure where to start? Our experts can help with that.</p>
                    <span className="break"></span>
                    <Link to="/contact-us" className="button green">Contact us</Link>
                    <span className="break"></span>
                    <h4>Phase 2: Act</h4>
                    <p>If your organization is prepared to go to the cloud, our experts can help you orchestrate your cloud 
                        migration. Whether your movement is simple or it has complicated steps. We can take your preferences 
                        for timing, cost, security, and availability to design and implement a migration strategy that is 
                        customized to fit your business needs.</p>
                    <span className="break"></span>
                    <h4>Phase 3: Optimize</h4>
                    <p>Here's our plan for you to optimize your cloud investment:</p>
                    <h5>Cost Saving</h5>
                    <p>When implementing a strategy for cloud implementation, planning should be done to maximize your cost 
                        optimizations. We can provide suggested strategies that take these factors into account to ensure 
                        value and efficiency.</p>
                    <h5>DevOps</h5>
                    <p>When applying cloud-enabled development and operations principles, your organization can see increases in deployment 
                        frequency, team productivity, release quality, and architecture quality. Allowing you to maximize value creation.</p>
                    <h5>Cloud-Native Development</h5>
                    <p>Our expert software developers take pride in creating cloud-native solutions that are easy to use and fast to maintain.</p>
                    <h5>Well-Architected Reviews</h5>
                    <p>Well-Architected Reviews are an evaluation that ensures best practices for secure, high-performing, resilient, and efficient 
                        infrastructure for your applications. The primary cloud providers release new services regularly. There are always 
                        new ways to optimize your workload, which previously were not available.</p>
                    <h5>Managed Services</h5>
                    <p>Organizations that are empowered by the cloud can benefit from increased agility and decreased overhead. Nevertheless, 
                        the need 
                        for their support remains, at Nuerex, we strive to create a plan that's tailored to fit your specific needs.</p>
                    <span className="break"></span>
                    <h4>Phase 4 – Innovation</h4>
                    <p>Are you ready to take the next step in accelerating your innovation with cloud technologies? Here's what's next:</p>
                    <p>Cloud technologies unlock innovation in a way that other versions never could. The cloud accelerates your capabilities with the following.</p>
                    <ul>
                        <li>1. It will allow you to capture and process more data</li>
                        <li>2. It gives you more time for value-adding activities.</li>
                        <li>3. It can leverage new skills that lead to new ideas.</li>
                        <li>4. Those new ideas give rise to prototypes.</li>
                        <li>5. The cloud can scale with your growth.</li>
                    </ul>
                    <span className="break"></span>
                    <div className="contact-box">
                    <h3>Are you ready to develop your IT engines to create value continuously?</h3>  
                    <span className="break"></span>
                    <div className="button-area">
                        <Link to="/contact-us" className="button default">Contact us</Link>
                    </div>
                    </div>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default CloudComputing
